import './App.css';
import Select from 'react-select'
import certificates from './certificates.json'
import React, { useState, useEffect } from 'react';
function App() {
  const defaultOption = { value: '', label: 'Select a competition.' }
  const [selectComp, setSelectCompt] =  useState(defaultOption)
  const [placeHolderText, setPlaceHolderText] =  useState('..')
  const [registrationNumber, setRegistrationNumber] =  useState('')
  const [selectedCertificates, setSelectedCertificates] =  useState(null)
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }
  const options = [
    defaultOption,
    { value: 'MV', label: 'Ramdan Competition 2022' },
    { value: 'KN', label: 'Knowledge Competition 2022' },
    { value: 'QC', label: 'Quran Competition 2022' }
  ]
  
  useEffect(() => {
    if(selectComp){
      setPlaceHolderText(selectComp.value+"..")
    }
  },[selectComp]);
  useEffect(() => {
    const getCertificates=()=>{
      if(certificates[registrationNumber]){
        return certificates[registrationNumber]
      }else if(certificates[selectComp.value+registrationNumber]){
        return certificates[selectComp.value+registrationNumber]
      }else{
        return null
      }
  
    }
    if(registrationNumber!==''){
      setSelectedCertificates(getCertificates())
    }
  },[registrationNumber,selectComp]);
  return (
    <div className="App">
      <header className="App-header">
        <Select onChange={(sel)=>{
          setSelectCompt(sel)
        }
        
      } styles={customStyles} defaultValue={defaultOption}
 options={options} />
        {selectComp.value !=='' && <><span>Now input your registration number</span>
        <input onChange={(event)=>setRegistrationNumber(event.target.value)} style={{margin:20, padding:15}} placeholder={placeHolderText}/></>}
        {(registrationNumber!=='' && selectedCertificates) && <div style={{paddingTop:20}}><span>Certificates</span><ul>{selectedCertificates.map((cert)=><li><a href={cert}>{registrationNumber}</a></li>)}</ul></div>}
        {(registrationNumber!=='' && !selectedCertificates) && <div style={{paddingTop:20}}><span>No certificates found...</span></div>}
      </header>
    </div>
  );
}

export default App;
